<template>
  <div class="container">
    <section class="row">
      <div class="text-center d-flex flex-column">
        <h2 class="no-border"
            style="margin-top: 100px">
          Ops, 404 not found.
        </h2>
        <router-link :to="{name: 'Home'}">Return Home</router-link>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>
